import styled from "styled-components"
import { Commonpara, Commonh5 } from "../Common/common.style"
import { device } from "../Common/device"

import { Row } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"

export const RemodellingSection = styled.section`
  padding: 100px 0px 70px;
  background: #f9fafc;
  @media ${device.tablet} {
    padding: 80px 10px 50px;
  }
`

export const RemodellingRow = styled(Row)`
  align-items: center;
`

export const RemodellingLayout = styled.div``

export const RemodellingCard = styled.div`
  background: #fff;
  cursor: pointer;
  min-height: 250px;
  padding: 25px;

  transition: all 0.5s;
  margin: 0px 0px 30px;
  overflow: hidden;
  background-size: 180px auto;
  background-repeat: no-repeat;
  background-position: right bottom;

  :hover {
    transition: all 0.5s;
    transform: translateY(-10px);
  }

  @media ${device.tablet} {
    //    margin:10px 0px;
  }
`

export const RemodellingCardImg = styled.div`
  margin-bottom: 15px;
`

// export const RemodellingCardIcon = styled.img`
//   min-height: 200px;
// `

export const RemodellingCardHeading = styled(Commonh5)`
  text-transform: capitalize;
  font-size: 1rem;
`

export const RemodellingCardPara = styled(Commonpara)``
