import React from "react"
import { graphql } from "gatsby"
import GatsImg from "gatsby-image"
import Headermenu from "../containers/RealestatePage1/HeaderMenuBS"
import Footer from "../containers/RealestatePage1/Footer"
import GlobalStyle from "../containers/RealestatePage1/Common/global-styles"
import RemodellingCards from "../containers/RealestatePage1/RemodellingCard"
import Fonts from "../containers/RealestatePage1/Common/fonts"
import "../components/layout.css"
import SEO from "../components/seo"
import {
  contentWrapper,
  content,
  remodelImg,
  beforeAfterWrapper,
  beforeAfterTxt,
} from "../containers/RealestatePage1/Common/markdownStyle.module.css"
import { Container, Row, Col } from "react-bootstrap"

function Remodellig({ data }) {
  console.log(data)
  const { html } = data.markdownRemark
  const { beforeImg, afterImg } = data.markdownRemark.frontmatter

  return (
    <>
      <Fonts />
      <GlobalStyle />
      <main>
        <SEO
          title="Remodellig and Renovations"
          description="Let us Renovate and remodel your home"
        />
        <Headermenu home={false} />
        <section className={contentWrapper}>
          <Container>
            <article id="remodelligSection">
              <div className={content}>
                <div dangerouslySetInnerHTML={{ __html: html }} />
                <div className={beforeAfterWrapper}>
                  <div className={remodelImg}>
                    <h1 className={beforeAfterTxt}>before</h1>

                    <GatsImg fluid={beforeImg.childImageSharp.fluid} />
                  </div>
                  <div className={remodelImg}>
                    <h1 className={beforeAfterTxt}>after</h1>
                    <GatsImg
                      fluid={afterImg.childImageSharp.fluid}
                      className={remodelImg}
                    />
                  </div>
                </div>
              </div>
            </article>
          </Container>
        </section>
        <RemodellingCards />
      </main>

      <Footer />
    </>
  )
}
export const query = graphql`
  query RemodelligQuery {
    markdownRemark(frontmatter: { title: { eq: "remodelling" } }) {
      id
      html
      frontmatter {
        beforeImg {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        afterImg {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default Remodellig
