import React from "react"
import {
  RemodellingSection,
  RemodellingLayout,
  RemodellingCard,
  RemodellingCardImg,
  RemodellingCardIcon,
  RemodellingCardHeading,
  RemodellingCardPara,
  RemodellingRow,
} from "./remodelling.style"
import { Container, Row, Col } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import GatsImg from "gatsby-image"

import { useStaticQuery, graphql, Link } from "gatsby"
import Fade from "react-reveal/Fade"

const RemodellingCards = () => {
  const JSONData = useStaticQuery(graphql`
    query {
      realestatePage1Json {
        Remodelling {
          DelayConstant
          RemodellingLayout {
            RemodellingCardIcon {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            RemodellingCardHeading
            RemodellingCardPara
          }
        }
      }
    }
  `)
  const RemodellingData = JSONData.realestatePage1Json.Remodelling
  return (
    <RemodellingSection>
      <Container>
        <RemodellingRow>
          <Col md="12" lg="12">
            <RemodellingLayout>
              <Row>
                {RemodellingData.RemodellingLayout.map((item, idx) => {
                  return (
                    <Col lg="4" md="6" sm="12">
                      <Fade delay={(idx + 1) * RemodellingData.DelayConstant}>
                        <RemodellingCard position={idx + 1}>
                          <RemodellingCardImg>
                            <GatsImg
                              fluid={
                                item.RemodellingCardIcon?.childImageSharp.fluid
                              }
                              alt=""
                            />
                          </RemodellingCardImg>
                          <RemodellingCardHeading>
                            {item.RemodellingCardHeading}
                          </RemodellingCardHeading>
                          <RemodellingCardPara>
                            {item.RemodellingCardPara}
                          </RemodellingCardPara>
                        </RemodellingCard>
                      </Fade>
                    </Col>
                  )
                })}
              </Row>
            </RemodellingLayout>
          </Col>
        </RemodellingRow>
      </Container>
    </RemodellingSection>
  )
}
export default RemodellingCards
